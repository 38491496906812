.filter-container {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 0 16px;
    height: 100%;
}

.fixed-tags {
    display: flex;
    align-items: center;
    flex-shrink: 0;
}

.scrollable-tags-container {
    flex: 1;
    min-width: 0;
    margin: 0 16px;
}

.scrollable-tags {
    display: flex;
    overflow-x: auto;
    white-space: nowrap;
}

.pagination-container {
    flex-shrink: 0;
    display: flex;
    align-items: center;
}

.ant-pagination.css-dev-only-do-not-override-5wsri9.ant-pagination-simple {
    margin-top: 0 !important;
}