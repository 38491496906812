.search-type-select {
    background: transparent !important;
    width: min(22vw, 125px) !important;
}

/* .ant-select-dropdown {
    width: 100px !important;
} */

/* WebKit-based browsers (Chrome, Safari, Edge, Opera) */
.content::-webkit-scrollbar {
    display: none;
}
  
/* Gecko-based browsers (Firefox) */
.content::-moz-scrollbar {
    display: none;
}