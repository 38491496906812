.valuations-grid {
    width: 100% !important;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(90px, 1fr));
    column-gap: 12px;
    row-gap: 12px;
    padding-right: 18px;
}

/* .ant-statistic-content {
    color: #1677ff !important;
} */

/* .valuations-grid :nth-child(2) {
    text-align: right;
} */

@media print {
    .printFormatting {
        min-width: 200px !important;
        width: 50% !important;
    }
}