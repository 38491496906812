.grid-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
    column-gap: 12px;
    row-gap: 12px;
}

.main-grid {
    display: grid;
    grid-template-columns: 1fr;
}

.grid-container > .ant-card > .ant-card-body {
  display: grid;
  place-items: center;
  padding: 0px !important;
}

.ant-typography-copy {
  font-size: 20px;
}

.icon-button {
  padding: 0 8px !important;
}

@media screen and (max-width: 768px) {
  .main-grid {
    display: block;
  }
}

/* hideOnPrint and showOnPrint not used anymore? */

@media screen {
  .showOnPrint {
    display: none;
  }
}

@media print {
  @page {
    margin: 15mm;
  }

  .hideOnPrint {
    display: none;
  }
}

.property-label {
    color: #001529;
    margin: 0 10px 0 0;
    font-size: 18px;
    /* font-weight: 500; */
    display: flex;
    align-items: center;
}

.property-value .ant-statistic-content {
    font-size: 18px;
    font-weight: 500;
    margin-right: 8px;
}

.deal-rating-tag {
    margin-left: 10px;
    font-size: 18px;
}

.recalc-button {
    padding: 0;
    margin: 0 0 0 10px;
    font-size: 18px;
}

/* Small variant classes */
.property-label-small {
    color: #001529;
    margin: 0 10px 0 0;
    font-size: 16px;
    display: flex;
    align-items: center;
}

.property-value-small .ant-statistic-content {
    font-size: 16px;
    margin-right: 8px;
}

.recalc-button-small {
    padding: 0;
    margin: 0 0 0 4px;
    font-size: 16px;
}

/* Media Queries */
@media screen and (max-width: 1200px) {
    .property-label,
    .property-value .ant-statistic-content,
    .deal-rating-tag,
    .recalc-button {
        font-size: 16px;
    }
    
    .property-label-small,
    .property-value-small .ant-statistic-content,
    .recalc-button-small {
        font-size: 14px;
    }

    .titleAddress {
      font-size: 26px !important;
    }
}

@media screen and (max-width: 992px) {
    .property-label,
    .property-value .ant-statistic-content,
    .deal-rating-tag,
    .recalc-button {
        font-size: 14px;
    }
    
    .property-label-small,
    .property-value-small .ant-statistic-content,
    .recalc-button-small {
        font-size: 13px;
    }

    .titleAddress {
      font-size: 23px !important;
    }
}

@media screen and (max-width: 768px) {
    .property-label,
    .property-value .ant-statistic-content,
    .deal-rating-tag,
    .recalc-button {
        font-size: 13px;
    }
    
    .property-label-small,
    .property-value-small .ant-statistic-content,
    .recalc-button-small {
        font-size: 12px;
    }

    .titleAddress {
      font-size: 19px !important;
    }
}
  
  