.background {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 200px;
  max-height: 400px;
  height: 20vw;
  background: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, .8)), url('../../../public/img/house-img-2.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center 60%;
}


.container {
    display: flex;
    overflow-x: scroll; /* this should be moved to the property slider */
    justify-content: left;
    padding: 0px 10px;
  }

  .hoverCard {
    height: 40px;
    transition: transform 0.3s cubic-bezier(.22,.03,0,.84);
  }

  .hoverCard:hover {
    transform: scale(1.05);
  }

  
  