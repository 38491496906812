.landing-layout {
    min-height: 100vh;
}

.landing-header {
    position: sticky;
    top: 0;
    z-index: 1;
    width: 100%;
    background: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 50px;
    box-shadow: 0 2px 8px rgba(0,0,0,0.06);
}

.logo {
    font-size: 1.5rem;
    font-weight: bold;
}

.hero-section {
    padding: 60px 50px;
    background: #faf9f7;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 40px;
}

.hero-content {
    flex: 1;
    max-width: 600px;
}

.hero-image {
    flex: 1;
    display: flex;
    justify-content: center;
}

.image-placeholder {
    width: 300px;
    height: 300px;
    border: 2px dashed #ccc;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #999;
}

.feature-visual {
    padding: 60px 50px;
    background: #262626;
    display: flex;
    justify-content: center;
}

.feature-visual .image-placeholder {
    width: 80%;
    max-width: 800px;
    height: 400px;
    border-radius: 8px;
    color: #fff;
}

.description-section {
    padding: 60px 50px;
}

.main-description {
    max-width: 800px;
    margin: 0 auto 60px;
    text-align: center;
    font-size: 1.1rem;
}

.features-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 40px;
    max-width: 1200px;
    margin: 0 auto;
}

.feature-item {
    text-align: center;
}

.feature-circle {
    width: 150px;
    height: 150px;
    border: 2px solid #ccc;
    border-radius: 50%;
    margin: 0 auto 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
}

@media (max-width: 768px) {
    .hero-section {
        flex-direction: column;
    }

    .features-grid {
        grid-template-columns: 1fr;
    }
}
